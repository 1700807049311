import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'

import HomeView from '../views/HomeView.vue'

import PasswordView from '../views/PasswordView.vue'

import DashboardView from '../views/DashboardView.vue'
import CalendarView from '../views/CalendarView.vue'
import FollowerView from '../views/FollowerView.vue'

import PostView from '../views/PostView.vue'

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: DashboardView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setFollower',
        name: 'Follower',
        component: FollowerView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/calendar',
        name: 'Kalender',
        component: CalendarView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/calendar/:year/:month',
        name: 'Kalender Monat',
        component: CalendarView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/calendar/:year/:month/:day',
        name: 'Kalender Tag',
        component: CalendarView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/post/:postId',
        name: 'Post',
        component: PostView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/post/:year/:month/:day',
        name: 'Neuer Post',
        component: PostView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/password',
        name: 'Passwort ändern',
        component: PasswordView,
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const login = (localStorage.username && localStorage.password)

  if (requiresAuth && !login) next('login')
  else if (!requiresAuth && login) next('Dashboard')
  else next()
})

export default router
