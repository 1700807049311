<template>
  <div id="dashboard">
    <h1 id="title">Dashboard</h1>
    <label v-on:click="changeAccount()" v-if="getAccountCount() > 1" id="switch">
      <span style="font-size: 0.75em;">{{getUserAssociation()}}</span>⇆
    </label>
    <PostList title="Heute" :date="new Date(new Date() - new Date().getTimezoneOffset() * 1000 * 60)" />
    <PostList :title="getUserRole() === 'reviewer' ? 'freizugeben' : 'ToDos'" :todo="true" />
    <div id="statsBox" class="contentBox">
      <div style="width: calc(100% - 1.5em); float: left;">
        <h2>Follower-Entwicklung</h2>
      </div>
      <router-link v-if="getUserName() === 'Sebastian'" to="/setFollower"><h2 style="width: 1em; text-align: center; float: right;">+</h2></router-link>
      <div v-on:click="loadFollowerData(31)" class="submitButton" :class="{active: statsTime === 31}">1 Monat</div>
      <div v-on:click="loadFollowerData(92)" class="submitButton" :class="{active: statsTime === 92}">3 Monate</div>
      <div v-on:click="loadFollowerData(183)" class="submitButton" :class="{active: statsTime === 183}">6 Monate</div>
      <div v-on:click="loadFollowerData()" class="submitButton" :class="{active: statsTime === null}">MAX</div>
      <div class="chart">
        <canvas id="followerChart" />
      </div>
    </div>
    <div id="footer"><router-link to="/password">Passwort ändern</router-link> | <span v-on:click="logout">Logout</span></div>
  </div>
</template>

<script>
import PostList from '../components/PostList'

import Konstanten from '../scripts/Konstanten'
import globalFunctions from '../scripts/globalFunctions'

import Chart from 'chart.js/auto'
import moment from 'moment'
import 'chartjs-adapter-moment'
import axios from 'axios'

export default {
  name: 'DashboardView',

  mixins: [Konstanten, globalFunctions],

  components: {
    PostList
  },
  data () {
    return {
      followerChart: null,
      followerData: [],
      statsTime: null
    }
  },

  methods: {
    logout: function () {
      localStorage.removeItem('password')
      this.$router.replace('Login')
    },

    loadFollowerData: function (statsTime = null) {
      this.statsTime = statsTime
      axios.post(this.getFunctionsURL(), {
        function: 'getFollowerStats',
        username: localStorage.username,
        password: localStorage.password,
        activeAccount: localStorage.userAccountActive,
        statsTime: this.statsTime
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else {
          let visibleCurves = []
          switch (this.getUserAssociation()) {
            case 'LV':
              visibleCurves = ['juliasachsen', 'fdp_sn']
              break
            case 'DD':
              visibleCurves = ['julia.dresden']
              break
            case 'L':
              visibleCurves = ['julisleipzig']
              break
            case 'CH':
              visibleCurves = ['julis_chemnitz', 'julis_vogtland']
              break
            default:
              visibleCurves = ['juliasachsen']
          }
          const colors = ['#E6007E', '#FFED00', '#009EE3', '#F5B5D2', '#FFF7B2', '#A1DAF8', '#A49BCB', '#ECB299']
          const datasets = ['juliasachsen', 'fdp_sn', 'hai.happen_', 'julia.dresden', 'julisleipzig', 'julis_chemnitz', 'julis_vogtland']
          this.followerData = []
          datasets.forEach((account) => {
            this.followerData.push({
              type: 'line',
              label: account,
              data: [],
              borderColor: colors[this.followerData.length % colors.length],
              hidden: !visibleCurves.includes(account)
            })
          })
          response.data.forEach((row) => {
            let index = datasets.indexOf(row.followerAccount)
            if (index === -1) {
              datasets.push(row.followerAccount)
              this.followerData.push({
                type: 'line',
                label: row.followerAccount,
                yAxisID: row.followerCount >= 1850 ? 'yHigh' : 'yLow',
                borderColor: colors[index % colors.length],
                hidden: true,
                data: []
              })
              index = this.followerData.length - 1
            }
            if (this.followerData[index].yAxisID === undefined) {
              this.followerData[index].yAxisID = row.followerCount >= 1850 ? 'yHigh' : 'yLow'
            }
            this.followerData[index].data.push({ x: moment(new Date(row.followerDate).getTime()), y: row.followerCount })
          })
        }
      }).then(() => {
        if (Chart.getChart('followerChart') !== undefined) {
          Chart.getChart('followerChart').destroy()
        }
        this.drawChart()
      })
    },

    drawChart: function () {
      this.followerChart = new Chart(document.getElementById('followerChart'), {
        type: 'line',
        data: {
          datasets: this.followerData
        },
        options: {
          animations: false,
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false
          },
          plugins: {
            title: {
              display: false
            },
            legend: {
              position: 'bottom'
            }
          },
          elements: {
            line: {
              tension: 0,
              backgroundColor: 'transparent'
            },
            point: {
              radius: 0
            }
          },
          scales: {
            xAxis: {
              type: 'time',
              time: {
                unit: 'day',
                round: 'day',
                displayFormats: {
                  day: 'DD.MM.'
                },
                tooltipFormat: 'DD.MM.YYYY'
              }
            },
            yHigh: {
              position: 'right',
              type: 'linear'
            },
            yLow: {
              position: 'left',
              type: 'linear'
            }
          }
        }
      })
    },

    changeAccount: function () {
      localStorage.userAccountActive = (parseInt(localStorage.userAccountActive) + 1) % this.getAccountCount()
      location.reload()
    }
  },

  mounted: function () {
    this.loadFollowerData(92)
  }
}
</script>

<style scoped>
#statsBox {
  width: calc(100% - 20px - 1em);
  padding: 0.5em;
  user-select: none;
}

#followerChart {
  min-height: 35vh;
  max-height: 35vh;
}

#footer {
  color: var(--softGray);
  text-align: center;
  font-size: 0.8em;
  cursor: pointer;
  user-select: none;
}

#footer * {
  color: inherit;
}

#footer *:hover {
  text-decoration: underline;
  color: var(--gray);
}

.submitButton {
  float: left;
  font-size: 0.8em;
  background: var(--softMagenta);
  border-color: var(--softMagenta);
  color: var(--softYellow);
}
.submitButton:hover, .submitButton:focus {
  color: var(--magenta);
}
.submitButton.active {
  color: var(--yellow);
  background: var(--magenta);
  border-color: var(--magenta);
}
@media (orientation:landscape) {
  .submitButton {
    width: calc((100% - 3 * 0.5em) / 4 - 20px);
    margin-left: 0.5em;
  }
  .submitButton:nth-of-type(4n+2) {
    margin-left: 0;
  }
}
@media (orientation:portrait) {
  .submitButton {
    width: calc(50% - 20px - 0.25em);
  }
  .submitButton:nth-of-type(2n+1) {
    margin-left: 0.5em;
  }
}
</style>
