<template>
  <div id="calendar">
    <h1 id="title">Kalender</h1>
    <label v-if="getUserAssociation() === 'LV'" id="switch">
      <input v-on:change="changeShowKVs" v-model="showKVs" type="checkbox">
      <span class="slider"></span>
    </label>
    <div id="calendarBorder" class="contentBox" @touchstart="touchStartMethod">
      <div class="calendarNavigator" style="text-align: left;" v-on:click="month = (month + 11) % 12; year -= month == 11 ? 1 : 0; loadPosts();">&lt;</div>
      <h2 id="calendarMonth" v-on:click="daySelected=new Date().getDate();month=new Date().getMonth();year=new Date().getFullYear(); loadPosts();">{{getMonthName(month)}} '{{year % 100}}</h2>
      <div class="calendarNavigator" style="text-align: right;" v-on:click="month = (month + 1) % 12; year += month == 0 ? 1 : 0; loadPosts();">&gt;</div>
      <div class="weekDay" v-for="weekDay in 7" :key="weekDay">{{['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'][weekDay-1]}}</div>
      <div class="notADay" v-for="notADay in ((new Date(year + '-' + (month + 1 < 10 ? '0' : '') + (month + 1) + '-01').getDay() + 6) % 7)" :key="notADay"></div>
      <div class="day" :class="{today: day==dayToday && month==new Date().getMonth() && year==new Date().getFullYear(), selected: day==daySelected}" v-for="day in getMonthDays(month, year)" :key="day" v-on:click="daySelected=day">
        <div class="dayDate">{{day}}</div>
        <div class="dayContent">
          <span v-for="i in posts.filter((item) => {return new Date(item.postDate).getDate() === day})" :key="i" class="dayPoint" :style="getPostColor(i.postType, i.postStatus, i.postAssociation)">⬤</span>
        </div>
      </div>
      <span style="clear: left; display: block;" />
    </div>
    <router-link v-if="getUserRole() !== 'reviewer'" id="newPost" :key="numbersToDate(daySelected, month, year)" class="contentBox" :to="'/post/' + year + '/' + (month + 1 < 10 ? '0' : '') + (month + 1) + '/' + (daySelected < 10 ? '0' : '') + daySelected">
      Neuer Post
    </router-link>
    <PostList :key="numbersToDate(daySelected, month, year)" :title="daySelected + '. ' + getMonthName(month) + ' ' + year" :date="numbersToDate(daySelected, month, year)" :showKVs="showKVs" />
  </div>
</template>

<script>
import axios from 'axios'

import PostList from '../components/PostList'

import Konstanten from '../scripts/Konstanten'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'CalendarView',

  mixins: [Konstanten, globalFunctions],

  components: {
    PostList
  },

  data () {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      dayToday: new Date().getDate(),
      daySelected: new Date().getDate(),
      posts: [],
      showKVs: false
    }
  },

  methods: {
    loadPosts: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getMonthPosts',
        username: localStorage.username,
        password: localStorage.password,
        activeAccount: localStorage.userAccountActive,
        month: this.month + 1,
        year: this.year,
        includeKVs: this.showKVs ? 1 : 0
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else {
          this.posts = response.data
        }
      })
    },

    getPostColor: function (type, status, association) {
      let color = ''
      if (this.showKVs) {
        switch (association) {
          case 'LV':
          case 'Coop':
            color = 'Magenta'
            break
          case 'DD':
            color = 'Yellow'
            break
          case 'L':
            color = 'Cyan'
            break
          case 'CH':
            color = 'Purple'
            break
        }
      } else {
        switch (type) {
          case 0:
          case 1:
            color = 'Magenta'
            break
          case 2:
            color = 'Cyan'
            break
          case 3:
            color = 'Yellow'
            break
          case 4:
          case 5:
            color = 'Black'
            break
        }
      }
      let toDo = false
      if (association === undefined || this.getUserAssociation() === association || (this.getUserAssociation() === 'LV' && association === 'Coop')) {
        switch (this.getUserRole()) {
          case 'admin':
            toDo = [0, 1, 3].includes(status)
            break
          case 'creator':
            toDo = [0, 1].includes(status)
            break
          case 'reviewer':
            toDo = [2].includes(status)
            break
        }
      }
      if (toDo) {
        return 'color: var(--' + color.toLowerCase() + ');'
      } else {
        if (color === 'Black') return 'color: var(--gray);'
        return 'color: var(--soft' + color + ');'
      }
    },

    changeShowKVs: function () {
      this.loadPosts()
      sessionStorage.showKVs = this.showKVs
    },

    touchStartMethod: function (touchEvent) {
      if (touchEvent.changedTouches.length !== 1) return null
      const posXStart = touchEvent.changedTouches[0].clientX
      addEventListener('touchend', (touchEvent) => this.touchEndMethod(touchEvent, posXStart), { once: true })
    },

    touchEndMethod: function (touchEvent, posXStart) {
      if (touchEvent.changedTouches.length !== 1) return null
      const posXEnd = touchEvent.changedTouches[0].clientX
      if (Math.abs(posXStart - posXEnd) < 50) return null
      if (posXStart < posXEnd) {
        this.month = (this.month + 11) % 12
        this.year -= this.month === 11 ? 1 : 0
      } else if (posXStart > posXEnd) {
        this.month = (this.month + 1) % 12
        this.year += this.month === 0 ? 1 : 0
      }
      this.loadPosts()
    }
  },

  mounted: function () {
    this.showKVs = sessionStorage.showKVs === 'true' && this.getUserAssociation() === 'LV'
    if (this.$route.params.year && this.$route.params.month) {
      this.year = parseInt(this.$route.params.year)
      this.month = Math.min(this.$route.params.month - 1, 11)
      if (this.$route.params.day) {
        this.daySelected = parseInt(this.$route.params.day)
      } else {
        this.daySelected = 1
      }
    }
    this.loadPosts()
  }
}
</script>

<style scoped>
#calendarBorder {
  width: calc(100% - 20px - 1em);
  padding: 0.5em;
  user-select: none;
}

.calendarNavigator {
  width: max(calc(10% - 0.5em), 0.5em);
  padding: 0.25em;
  display: inline-block;
  font-family: Anybody ExtraBold;
  color: var(--magenta);
}

#calendarMonth {
  width: min(calc(80% - 0.5em), calc(100% - 2em - 0.5em));
  display: inline-block;
  text-align: center;
}

.weekDay {
  display: inline-block;
  width: calc((100% - 1px) / 7 - 10px);
  margin: 0 2px 0 2px;
  border: 3px solid transparent;
  text-align: center;
  font-family: Anybody;
}

.day, .notADay {
  display: inline-block;
  width: calc((100% - 1px) / 7 - 10px);
  height: 2.1em;
  margin: 2px;
  border: 3px solid var(--softMagenta);
  overflow: hidden;
  float: left;
}

.notADay {
  border-color: transparent;
  color: transparent;
}

.day.today {
  border-color: var(--magenta);
}

.day.selected {
  background: var(--softMagenta);
}
.day.selected .dayDate {
  color: var(--gray);
}

.dayDate {
  color: var(--softGray);
  text-align: right;
  padding-right: 2px;
  font-family: Anybody ExtraBold;
  font-size: 1em;
  line-height: 1;
}

.dayContent {
  text-align: center;
  display: inline-block;
  width: calc(100% - 4px);
  padding: 0 2px;
}

.dayPoint {
  margin-left: 0;
  margin-right: -0.35em;
  text-shadow: 0 0 5px var(--gray);
}

.dayPoint:last-child {
  margin-right: 0;
}

#newPost {
  display: inline-block;
  color: var(--yellow);
  background: var(--magenta);
  font-family: Anybody Light;
  font-weight: bold;
  text-align: center;
  user-select: none;
  cursor: pointer;
  text-decoration: none;
}

input:checked + .slider {
 background-color: var(--magenta);
}

input:checked + .slider:before {
  -webkit-transform: translateX(1em);
  -ms-transform: translateX(1em);
  transform: translateX(1em);
}
</style>
