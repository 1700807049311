<template>
  <router-view />
</template>

<style>
:root {
  --magenta: #E6007E;
  --yellow: #FFED00;
  --cyan: #009EE3;
  --softMagenta: #F5B5D2;
  --softYellow: #FFF7B2;
  --softCyan: #A1DAF8;
  --softPurple: #A49BCB;
  --softOrange: #ECB299;
  --black: #000000;
  --softGray: #CCCCCC;
  --gray: #888888;
  --white: #FFFFFF;
}

@font-face {
  font-family: 'Anybody';
  src: url('./assets/fonts/Anybody-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Anybody ExtraBold';
  src: url('./assets/fonts/Anybody-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Anybody Light';
  src: url('./assets/fonts/Anybody-Light.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Anybody Light';
  src: url('./assets/fonts/Anybody-Bold.ttf') format('truetype');
  font-weight: bold;
}

body {
  font-family: Anybody Light, Calibri, sans-serif;
  color: var(--black);
  background: var(--softYellow);
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  font-size: 1.2em;
  line-height: 1.1;
}

h1 {
  font-family: Anybody ExtraBold;
  font-size: 2em;
  color: var(--magenta);
  margin: 0;
  user-select: none;
}

h2 {
  font-family: Anybody;
  font-size: 1em;
  color: var(--magenta);
  background: var(--yellow);
  width: fit-content;
  padding: 0.25em;
  border-radius: 0.5em;
  margin: 0;
  user-select: none;
}

b, em {
  font-family: Anybody;
  font-weight: bold;
}

a {
  color: var(--magenta);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.contentBox {
  margin-bottom: 0.5em;
  border: 10px solid var(--magenta);
  border-radius: 1em;
  width: calc(100% - 20px);
  background: var(--white);
}
.contentBox:last-child {
  margin-bottom: 0;
}

.button {
  color: var(--magenta);
  font-family: Anybody Light;
  font-weight: bold;
  text-align: center;
  user-select: none;
  cursor: pointer;
  border: 5px solid var(--magenta);
  border-radius: 1em;
  width: calc(100% - 10px);
  margin-top: 0.5em;
}

.submitButton {
  color: var(--yellow);
  background: var(--magenta);
  font-family: Anybody Light;
  font-weight: bold;
  text-align: center;
  user-select: none;
  cursor: pointer;
  border: 10px solid var(--magenta);
  border-radius: 1em;
  width: calc(100% - 20px);
  margin-top: 0.5em;
}

#title {
  display: inline-block;
  width: calc(100% - 2em);
}

#switch {
  display: inline-block;
  position: relative;
  font-size: 2em;
  width: 2em;
  height: 1em;
  text-align: right;
  color: var(--magenta);
}

#switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 1em;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--softMagenta);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  border-radius: 50%;
  height: 0.8em;
  width: 0.8em;
  left: 0.1em;
  bottom: 0.1em;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
</style>
