<template>
  <div id="post">
    <div id="toast">Gespeichert...</div>
    <h1>Post</h1>
    <div id="calendarBorder" class="contentBox">
      <h2>Thema</h2>
      <input v-model="postTopic" maxlength="128" type="text" :disabled="!isEditable()" />
      <div class="halfWidth">
        <h2>Typ</h2>
        <select v-model="postType" :disabled="!isEditable()">
          <option :value="0">Beitrag</option>
          <option :value="1">Karussell</option>
          <option :value="2">Reel</option>
          <option :value="3">Story</option>
          <option :value="4">Newsletter</option>
          <option :value="5">Pressemitteilung</option>
        </select>
      </div>
      <div class="halfWidth">
        <h2>Datum</h2>
        <input v-model="postDate" type="date" :disabled="!isEditable()" />
      </div>
      <div class="halfWidth">
        <h2>Ersteller</h2>
        <select v-model="postResponsibility" :disabled="!isEditable()">
          <option :value="null">nicht zugewiesen</option>
          <option v-for="creator in creatorsList" :key="creator.userName" :value="creator.userName">{{creator.userName}}</option>
        </select>
      </div>
      <div class="halfWidth">
        <h2>Budget</h2>
        <div class="euro">
          <input v-model="postBudget" type="number" :disabled="!isEditable()" step="0.5" />
          <i>€</i>
        </div>
      </div>
      <div :class="{ halfWidth: getUserAssociation() === 'LV' || postAssociation === 'Coop' }">
        <h2>Status</h2>
        <select v-model="postStatus" :disabled="!statusEditable()">
          <option :value="-1" v-if="getUserRole() === 'admin'">Löschen</option>
          <option :value="0" :disabled="getUserRole() === 'reviewer'">Idee</option>
          <option :value="1" :disabled="getUserRole() === 'reviewer'">Entwurf</option>
          <option :value="2">Fertig</option>
          <option :value="3" :disabled="getUserRole() === 'creator'">Freigegeben</option>
          <option :value="4" :disabled="['reviewer', 'creator'].includes(getUserRole())">{{[4, 5].includes(parseInt(postType)) ? 'Versendet' : 'Gepostet'}}</option>
        </select>
      </div>
      <div :class="{ halfWidth: getUserAssociation() === 'LV' || postAssociation === 'Coop' }">
        <h2>Verband</h2>
        <select v-model="postAssociation" :disabled="!isEditable()">
          <option value="LV" :disabled="getUserAssociation() !== 'LV'">Landesverband</option>
          <option value="Coop" :disabled="getUserAssociation() !== 'LV'">Coop</option>
          <option value="DD" :disabled="getUserAssociation() !== 'DD'">Dresden</option>
          <option value="L" :disabled="getUserAssociation() !== 'L'">Leipzig</option>
          <option value="CH" :disabled="getUserAssociation() !== 'CH'">Chemnitz</option>
        </select>
      </div>
      <span v-if="'2024-06-22' <= postDate && postDate <= '2024-09-01'">
        <h2>Kampagnen&shy;slogan</h2>
        <input v-model="postCampaign" maxlength="64" type="text" :disabled="!isEditable()" />
      </span>
      <h2>
        <span v-if="[4, 5].includes(parseInt(postType))">Betreff</span>
        <span v-else>Slogan</span>
      </h2>
      <textarea rows="1" ref="slogan" maxlength="128" v-model="postSlogan" style="font-weight: bold; min-height: 2.1em;" oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'" :disabled="!isEditable()" />
      <span v-if="postType !== 3">
        <h2>Text</h2>
        <textarea v-model="postText" id="text" :disabled="!isEditable()" />
      </span>
      <span v-if="isEditable() || (postNotes && postNotes.length > 0)">
        <h2>Notizen</h2>
        <textarea ref="notes" v-model="postNotes" oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'" :disabled="!isEditable()" />
      </span>
      <span v-if="![4, 5].includes(parseInt(postType))">
        <h2 v-if="isEditable() || tweets.length > 0">Twitter/X</h2>
        <textarea v-for="tweet in tweets" class="tweet" :key="tweet.tweetId" v-model="tweets[tweet.tweetId - 1].tweetText" maxlength="280" oninput="this.style.height = '';this.style.height = this.scrollHeight + 'px'" :disabled="!isEditable()" />
        <div v-on:click="tweets.push({tweetId: tweets.length + 1, tweetText: ''})" v-if="isEditable() && (tweets.length === 0 || tweets[tweets.length - 1].tweetText.length > 0)" class="button" style="font-size: 2em; line-height: 0.75em; margin-bottom: 0.25em;" :style="'margin-top: ' + (tweets.length ? 0 : 0.25) + 'em;'">+</div>
        <div v-on:click="tweets.pop()" v-if="isEditable() && tweets.length > 0 && tweets[tweets.length - 1].tweetText.length === 0" class="button" style="font-size: 2em; line-height: 0.75em; margin-bottom: 0.25em; margin-top: 0;">-</div>
      </span>
      <h2 v-if="isEditable() || media.length > 0">Medien</h2>
      <div v-if="media.length > 0" style="display: inline-block; width: 100%;">
        <div v-for="m in media" :key="m">
          <div v-if="m.url" class="deleteButton" v-on:click="deleteMedia(m)">X</div>
          <a :href="m.url" target="_blank" v-if="m.url">
            <img v-if="m.mediaType.includes('image') && media.length <= 2" class="media image wenige" :src="m.url + (m.mediaName ? '&maxHeight=750&maxWidth=600' : '')" />
            <img v-if="m.mediaType.includes('image') && media.length > 2" class="media image viele" :src="m.url + (m.mediaName ? '&maxHeight=250&maxWidth=200' : '')" />
            <div v-if="!m.mediaType.includes('image')" class="media text" :class="{ viele: media.length > 2, wenige: media.length <= 2}">{{m.mediaName}}</div>
          </a>
        </div>
      </div>
      <div v-if="isEditable()" onclick="document.getElementById('mediaUploader').click();" class="button" style="font-size: 2em; line-height: 0.75em; margin-bottom: 0.25em;" :style="'margin-top: ' + (tweets.length ? 0 : 0.25) + 'em;'">+</div>
      <input type="file" accept="image/*, video/*" id="mediaUploader" v-on:change="addMedia" style="display: none;" />
      <div v-if="postStatus !== -1 && (isEditable() || statusEditable())" :class="{ loading: laden > new Date().getTime() }" v-on:click="store" class="submitButton">Speichern</div>
      <div v-if="postStatus === -1 && (isEditable() || statusEditable())" v-on:click="deletePost" class="submitButton">Löschen</div>
      <div v-if="!(isEditable() || statusEditable())" v-on:click="goBack" class="submitButton">Zurück</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Konstanten from '../scripts/Konstanten'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'CalendarView',

  mixins: [Konstanten, globalFunctions],

  data () {
    return {
      postId: null,
      postTopic: null,
      postType: 0,
      postCampaign: null,
      postSlogan: null,
      postDate: null,
      postText: null,
      postNotes: null,
      postStatus: 0,
      tweets: [],
      media: [],
      laden: Infinity,
      postAssociation: null,
      postResponsibility: null,
      postBudget: 0,
      creatorsList: []
    }
  },

  methods: {
    loadPost: function () {
      this.laden = Infinity
      axios.post(this.getFunctionsURL(), {
        function: 'getPost',
        username: localStorage.username,
        password: localStorage.password,
        activeAccount: localStorage.userAccountActive,
        postId: this.postId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else if (response.data === 'Post nicht gefunden') {
          this.$router.push('/calendar')
        } else {
          this.postTopic = response.data.postTopic
          this.postSlogan = response.data.postSlogan
          this.postDate = response.data.postDate
          this.postType = response.data.postType
          this.postText = response.data.postText
          this.postCampaign = response.data.postCampaignWord
          this.postStatus = response.data.postStatus
          this.postNotes = response.data.postNotes
          this.tweets = response.data.tweets
          this.media = response.data.media
          this.postAssociation = response.data.postAssociation
          this.postResponsibility = response.data.postResponsibility
          this.postBudget = parseFloat(response.data.postBudget)
        }
      }).then(() => {
        this.loadCreators()
        this.media.forEach((element) => {
          element.url = 'https://content.julia-sachsen.de/getMedia.php?username=' + localStorage.username + '&password=' + localStorage.password + '&mediaFile=' + element.mediaFile + '&mediaName=' + element.mediaName + '&mediaType=' + element.mediaType
        })
        this.$refs.slogan.style.height = ''
        this.$refs.slogan.style.height = this.$refs.slogan.scrollHeight + 'px'
        if (this.$refs.notes) {
          this.$refs.notes.style.height = ''
          this.$refs.notes.style.height = this.$refs.notes.scrollHeight + 'px'
        }
        const tweetElements = document.getElementsByClassName('tweet')
        Array.from(tweetElements).forEach((element) => {
          element.style.height = ''
          element.style.height = element.scrollHeight + 'px'
        })
      }).then(() => {
        this.laden = 0
      })
    },

    loadCreators: function () {
      axios.post(this.getFunctionsURL(), {
        function: 'getCreators',
        username: localStorage.username,
        password: localStorage.password,
        activeAccount: localStorage.userAccountActive,
        postAssociation: this.postAssociation === 'Coop' ? 'LV' : this.postAssociation
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else {
          this.creatorsList = response.data
        }
      })
    },

    goBack: function () {
      const date = new Date(this.postDate)
      this.$router.push('/calendar/' + date.getFullYear() + '/' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '/' + (date.getDate() < 10 ? '0' : '') + date.getDate())
    },

    store: function (goBack = true) {
      if (this.getUserRole() === 'reviewer') {
        this.storeStatus(goBack)
      } else {
        this.storePost(goBack)
      }
    },

    storeStatus: function (goBack) {
      if (this.laden > new Date().getTime()) return null
      this.laden = new Date().getTime() + 5 * 1000
      axios.post(this.getFunctionsURL(), {
        function: 'storePostStatus',
        username: localStorage.username,
        password: localStorage.password,
        activeAccount: localStorage.userAccountActive,
        postId: this.postId,
        postStatus: this.postStatus
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else {
          if (goBack) {
            this.goBack()
          } else {
            const x = document.getElementById('toast')
            x.classList.add('show')
            setTimeout(function () { x.classList.remove('show') }, 2500)
          }
        }
      }).then(() => {
        this.laden = 0
      })
    },

    storePost: function (goBack) {
      if (this.laden > new Date().getTime()) return null
      this.laden = new Date().getTime() + 5 * 1000
      let mediaFinished = 0
      this.media.forEach((element) => {
        if (element.new) {
          const formData = new FormData()
          formData.append('function', 'storeMedia')
          formData.append('username', localStorage.username)
          formData.append('password', localStorage.password)
          formData.append('activeAccount', localStorage.userAccountActive)
          formData.append('postId', this.postId)
          formData.append('mediaId', element.mediaId)
          formData.append('media', element.file)
          formData.append('mediaType', element.mediaType)
          axios.post(this.getFunctionsURL(), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(response => {
            mediaFinished++
            if (mediaFinished === this.media.length) this.storeTexts(goBack)
          })
        } else {
          mediaFinished++
          if (mediaFinished === this.media.length) this.storeTexts(goBack)
        }
      })
      if (this.media.length === 0) this.storeTexts(goBack)
    },

    deleteMedia: function (m) {
      if (this.laden > new Date().getTime()) return null
      if (!confirm('Diese Datei wirklich endgültig löschen?')) return null
      this.laden = new Date().getTime() + 5 * 1000
      if (m.new) {
        this.media.forEach((element) => {
          if (element.mediaId > m.mediaId) element.mediaId -= 1
        })
        this.media.splice(this.media.indexOf(m), 1)
        this.laden = 0
      } else {
        const formData = new FormData()
        formData.append('function', 'deleteMedia')
        formData.append('username', localStorage.username)
        formData.append('password', localStorage.password)
        formData.append('activeAccount', localStorage.userAccountActive)
        formData.append('postId', this.postId)
        formData.append('mediaId', m.mediaId)
        axios.post(this.getFunctionsURL(), formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(response => {
          this.media.forEach((element) => {
            if (element.mediaId > m.mediaId) element.mediaId -= 1
          })
          this.media.splice(this.media.indexOf(m), 1)
          this.laden = 0
        })
      }
    },

    storeTexts: function (goBack = true) {
      axios.post(this.getFunctionsURL(), {
        function: 'storePost',
        username: localStorage.username,
        password: localStorage.password,
        activeAccount: localStorage.userAccountActive,
        postId: this.postId,
        postTopic: this.postTopic,
        postCampaignWord: this.postCampaign,
        postSlogan: this.postSlogan,
        postDate: this.postDate,
        postType: this.postType,
        postText: this.postText,
        postStatus: this.postStatus,
        postNotes: this.postNotes,
        tweets: this.tweets,
        postAssociation: this.postAssociation,
        postResponsibility: this.postResponsibility,
        postBudget: this.postBudget
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else {
          if (goBack) {
            this.goBack()
          } else {
            const x = document.getElementById('toast')
            x.classList.add('show')
            setTimeout(function () { x.classList.remove('show') }, 2500)
          }
        }
      }).then(() => {
        this.laden = 0
      })
    },

    deletePost: function () {
      if (this.laden > new Date().getTime()) return null
      this.laden = new Date().getTime() + 5 * 1000
      axios.post(this.getFunctionsURL(), {
        function: 'deletePost',
        username: localStorage.username,
        password: localStorage.password,
        activeAccount: localStorage.userAccountActive,
        postId: this.postId
      }, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(response => {
        if (response.data === 'Falsche Zugangsdaten') {
          alert('Zugangsdaten falsch.')
          localStorage.removeItem('password')
        } else {
          this.goBack()
        }
      })
    },

    addMedia: function (event) {
      const file = document.getElementById('mediaUploader').files[0]
      this.media.push({
        mediaId: this.media.length + 1,
        file: file,
        new: true,
        url: URL.createObjectURL(file),
        mediaType: file.type
      })
    },

    isEditable: function () {
      if (this.postAssociation && this.getUserAssociation() !== this.postAssociation && (this.postAssociation !== 'Coop' || this.getUserAssociation() !== 'LV')) return false
      if (this.getUserRole() === 'admin') return true
      if (this.getUserRole() === 'creator') return this.postStatus <= 3
      if (this.getUserRole() === 'reviewer') return false
      return false
    },

    statusEditable: function () {
      if (this.postAssociation && this.getUserAssociation() !== this.postAssociation && (this.postAssociation !== 'Coop' || this.getUserAssociation() !== 'LV')) return false
      if (this.getUserRole() === 'admin') return true
      if (this.getUserRole() === 'creator') return [0, 1, 2].includes(this.postStatus)
      if (this.getUserRole() === 'reviewer') return [1, 2, 3].includes(this.postStatus)
      return false
    }
  },

  mounted: function () {
    this.postId = null
    if (this.$route.params.postId) {
      this.postId = this.$route.params.postId
      this.loadPost()
    } else if (this.$route.params.year && this.$route.params.month && this.$route.params.day) {
      this.postDate = this.$route.params.year + '-' + this.$route.params.month + '-' + this.$route.params.day
      this.postAssociation = this.getUserAssociation()
      this.loadCreators()
      this.laden = 0
    }

    onkeydown = (e) => {
      if (e.ctrlKey && e.keyCode === 'S'.charCodeAt(0)) {
        e.preventDefault()
        this.store(false)
      }
    }
  }
}
</script>

<style scoped>
#calendarBorder {
  width: calc(100% - 20px - 1em);
  height: calc(100vh - 2em - 2.2em - 2em - 1em - 20px);
  padding: 0.5em;
  user-select: none;
  overflow: hidden auto;
}

#calendarBorder h2 {
  font-size: 1em;
}

#calendarBorder input,
#calendarBorder select,
#calendarBorder textarea {
  width: calc(100% - 0.4em);
  padding: 0.2em 0.2em 0 0.2em;
  border-color: var(--softMagenta);
  border-style: solid;
  border-width: 0 0 3px 0;
  outline: none;
  font-family: Anybody Light;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  background: var(--white);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#calendarBorder input:disabled,
#calendarBorder select:disabled,
#calendarBorder textarea:disabled {
  color: inherit;
}

#calendarBorder input:focus,
#calendarBorder select:focus,
#calendarBorder textarea:focus {
  border-color: var(--magenta);
  background: var(--softMagenta);
}

.halfWidth {
  width: 50%;
  display: inline-block;
}

@media (orientation:landscape) {
  #text {
    height: 30%;
  }
}
@media (orientation:portrait) {
  #text {
    height: 70%;
  }
}

.media {
  object-fit: contain;
  text-align: center;
  font-weight: bold;
  color: var(--magenta);
  max-width: 65vh;
  cursor: zoom-in;
}
.media.image {
  font-size: 2em;
}
.media.text {
  font-size: 1em;
  padding: 2em 0;
}

.media.wenige {
  margin: 2.5px;
  border: 5px solid var(--magenta);
  width: calc(100% - 15px);
  float: left;
  display: inline-block;
}
.media.image.wenige {
  border-radius: 0.5em;
}
.media.text.wenige {
  border-radius: 1em;
}

.media.viele {
  margin: 2.5px;
  border: 5px solid var(--magenta);
  float: left;
  display: inline-block;
  aspect-ratio: 4/5;
}
.media.image.viele {
  border-radius: 0.25em;
}
.media.text.viele {
  border-radius: 0.5em;
}

@media (max-width: 1200px) {
  .media.viele {
    width: calc((100% + 5px) / 3 - 15px);
  }
  .media.viele:nth-of-type(3n) {
    margin-right: 0;
  }
  .media.viele:nth-of-type(3n+1) {
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .media.viele {
    width: calc((100% + 5px) / 4 - 15px);
  }
  .media.viele:nth-of-type(4n) {
    margin-right: 0;
  }
  .media.viele:nth-of-type(4n+1) {
    margin-left: 0;
  }
}

#toast {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  max-width: 50%;
  background-color: var(--yellow);
  color: var(--magenta);
  font-family: Anybody ExtraBold;
  text-align: center;
  border-radius: 0.5em;
  padding: 0.5em;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: calc(2em / 3 + 10px);
  font-size: 1.5em;
  user-select: none;
  cursor: wait;
}

#toast.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2s;
  animation: fadein 0.5s, fadeout 0.5s 2s;
}

@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: calc(2em / 3 + 10px); opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: calc(2em / 3 + 10px); opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: calc(2em / 3 + 10px); opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: calc(2em / 3 + 10px); opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

.euro {
  position: relative;
}

.euro > i {
  position: absolute;
  display: block;
  top: 0.2em;
  pointer-events: none;
  width: 1.3em;
  text-align: center;
  font-style: normal;
  font-size: 0.9em;
}

.euro > input {
  padding-left: 1.3em !important;
  width: calc(100% - 1.5em) !important;
}

.deleteButton {
  height: 1em;
  width: 1em;
  border-radius: 1.5rem;
  border: var(--magenta) solid 2px;
  background: var(--white);
  color: var(--magenta);
  font-weight: bold;
  z-index: 2;
  position: relative;
  text-align: center;
  padding: 0;
  margin: 0.5em 0.5em calc(-1em - 0.5em - 4px) 0.5em;
  float: left;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .deleteButton {
    font-size: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .deleteButton {
    font-size: 1.75rem;
  }
}

.loading {
  cursor: wait;
}

</style>
