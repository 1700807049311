export default {
  methods: {
    numbersToDate: function (day, month, year) {
      return new Date(Date.UTC(year, month, day))
    },

    getMonthDays: function (month, year) {
      if ([0, 2, 4, 6, 7, 9, 11].includes(month)) return 31
      if ([3, 5, 8, 10].includes(month)) return 30
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) return 29
      return 28
    },

    getUserName: function () {
      return localStorage.username
    },

    getUserRole: function () {
      if (localStorage.getItem('userAccounts') === null) return localStorage.userRole
      return JSON.parse(localStorage.userAccounts)[localStorage.userAccountActive].userRole
    },

    getUserAssociation: function () {
      if (localStorage.getItem('userAccounts') === null) return localStorage.userAssociation
      return JSON.parse(localStorage.userAccounts)[localStorage.userAccountActive].userAssociation
    },

    getAccountCount: function () {
      if (localStorage.getItem('userAccounts') === null) return 1
      return JSON.parse(localStorage.userAccounts).length
    }
  }
}
