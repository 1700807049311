<template>
  <div id="detailsBorder" class="contentBox">
    <div id="detailsDate">{{title}}</div>
    <div id="detailsList" :class="{slim: date}">
      <router-link v-for="post in posts" :key="post.postId" :to="'/post/' + post.postId" class="contentInList" :class="{unassigned: todo && !((post.postResponsibility === null || post.postResponsibility === getUserName() || post.postStatus > 2) && ((getUserRole() === 'admin' && ![2,4].includes(post.postStatus)) || (getUserRole() === 'creator' && ![2,3,4].includes(post.postStatus)) || (getUserRole() === 'reviewer' && [3].includes(post.postStatus))))}">
        <p class="status">
          <span v-if="post.postStatus == 0">💡</span>
          <span v-if="post.postStatus == 1">✏️</span>
          <span v-if="post.postStatus == 2">✔️</span>
          <span v-if="post.postStatus == 3">✅</span>
          <span v-if="post.postStatus == 4">✉️</span>
        </p>
        <img class="postImg" :src="toPreviewUrl(post.postImage, post.postImageType)" />
        <div class="detailsText">
          <p v-if="post.postAssociation === 'Coop'" class="oneliner"><b>Coop-Post</b></p>
          <p v-if="!date" class="oneliner"><b>Datum:</b> {{new Date(post.postDate).toLocaleString("de-DE", {weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric'})}}</p>
          <p v-if="post.postResponsibility && post.postStatus < 3" class="oneliner"><b>zugewiesen:</b> {{post.postResponsibility}}</p>
          <p v-if="post.postTopic" class="oneliner"><b>Thema:</b> {{post.postTopic}}</p>
          <p v-if="post.postSlogan" class="oneliner"><b>Slogan:</b> {{post.postSlogan}}</p>
          <p v-if="post.postText"><b>Text:</b> {{post.postText}}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Konstanten from '../scripts/Konstanten'
import globalFunctions from '../scripts/globalFunctions'

export default {
  name: 'PostList',

  mixins: [Konstanten, globalFunctions],

  props: {
    title: String,
    date: Date,
    fromDate: Date,
    showKVs: { type: Boolean, default: false },
    todo: { type: Boolean, default: false }
  },

  data () {
    return {
      posts: []
    }
  },

  methods: {
    toPreviewUrl: function (imgName, imgType) {
      if (imgName !== null) {
        return 'https://content.julia-sachsen.de/getMedia.php?username=' + localStorage.username + '&password=' + localStorage.password + '&mediaFile=' + imgName + '&mediaType=' + imgType + '&maxHeight=100&maxWidth=90'
      } else {
        return require('../assets/logo.png')
      }
    }
  },

  mounted: function () {
    const params = {}
    if (this.date) params.date = this.date
    if (this.fromDate) params.fromDate = this.fromDate
    if (this.todo) params.todo = this.todo
    axios.post(this.getFunctionsURL(), Object.assign({
      function: 'getPosts',
      username: localStorage.username,
      password: localStorage.password,
      activeAccount: localStorage.userAccountActive,
      includeKVs: this.showKVs ? 1 : 0
    }, params), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(response => {
      if (response.data === 'Falsche Zugangsdaten') {
        alert('Zugangsdaten falsch.')
        localStorage.removeItem('password')
      } else {
        this.posts = response.data
        console.log(response.data)
      }
    })
  }
}
</script>

<style scoped>
#detailsBorder {
  user-select: none;
  overflow: hidden;
}

#detailsDate {
  color: var(--yellow);
  background: var(--magenta);
  font-family: Anybody;
  text-align: center;
  padding-bottom: 10px;
}

#detailsList {
  min-height: calc(4.4em * 0.5);
  max-height: calc(4.4em * 2.5 + 0.5em * 2);
  padding: 0.5em;
  overflow: hidden auto;
}

#detailsList.slim {
  max-height: calc(4.4em * 1.75 + 0.5em);
}

.contentInList {
  display: block;
  height: 4.4em;
  overflow: hidden;
  margin-bottom: 0.5em;
  color: var(--black);
  text-decoration: none;
}

.contentInList:last-of-type {
  margin-bottom: 0;
}

.postImg {
  height: 4.4em;
  width: 3.9em;
  padding-right: 0.5em;
  object-fit: contain;
  float: left;
}

.detailsText {
  display: inline-block;
  float: left;
  width: calc(100% - 4.4em);
}

.detailsText p {
  margin: 0;
  hyphens: auto;
}
@media (orientation:portrait) {
  .detailsText p {
    font-size: calc(4em / 6);
  }
}

.detailsText p.oneliner {
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 1.1em;
  overflow: hidden;
}

.unassigned {
  opacity: 0.5;
}

.status {
  position: relative;
  margin: 0 0 0 -0.1em;
  height: 0;
  padding-top: 0.1em;
  font-family: Anybody ExtraBold;
  font-size: 1.5em;
}
</style>
