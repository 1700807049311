export default {
  methods: {
    getFunctionsURL: function () {
      return 'https://content.julia-sachsen.de/functions.php'
    },

    getMonthName: function (number) {
      return ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'][number]
    }
  }
}
